







































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import _, { isString } from 'lodash';
import qs from 'qs';
import { Context } from '@nuxt/types';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { adminHost, handleNewsUrl } from '~/util/common';
import GoodsItem from '~/components/Goods/item.vue';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  filters: {
    description(item: Record<string, any>): any {
      const content = item.description || item.content;
      const reg = /<[^>]+>/gi;
      return content
        .replace(reg, '')
        .replace(/&nbsp;/g, '')
        .trim();
    },
  },
  components: { GoodsItem },
  // 校验params参数
  validate(ctx: Context) {
    const { path } = ctx.route;
    const { keyword } = ctx.query;
    if (isString(keyword)) {
      // 搜索词不能包含 网址、網纸；只能输入字母和汉字、搜索词长度必须小于等于 50
      return (
        !/网址|網纸/.test(keyword) &&
        /^[a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword) &&
        keyword.length <= 50
      );
    } else {
      if (path === '/exporec' || path === '/exporec/') {
        return true;
      }
      const _validate = /exporec-(\d+)-(\d+)/.test(path);

      return _validate;
    }
  },
  async asyncData(ctx) {
    try {
      // id-1
      const { keyword } = ctx.query; //
      const lang = _.get(ctx, 'query.lang', null);
      let langApi = 'cn';
      if (lang == 'zh') langApi = 'cn';
      if (lang == 'en') langApi = 'en';

      const { f: id, page = 1 } = ctx.params;
      const pageNum = isNaN(+page) ? 1 : +page;
      const pageSize = 30;
      // 搜索列表
      let listParams: Record<string, any> = {
        locale: langApi,
        recommendId: id,
        returnExtraFields: ['industries'],
        pageNum,
        pageSize,
        // regionType: type,
        // selectTimeType: 1,
        // time: moment().format('YYYY-MM-DD'),
      };

      let requestList: any[] = [];

      requestList = [
        // ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`), // 获取所有行业
        ctx.$axios.get(`/api/exhibition/city/1`), // 获取所有国际地区
        ctx.$axios.post('/api/recommendApi/basic', {
          id,
        }),
        ctx.$axios.post('/api/exhibition/list', {
          ...listParams,
          status: 0,
        }),
        ctx.$axios.post('/api/exhibition/list', {
          ...listParams,
          pageNum: 1,
          pageSize: 10,
          status: 1, // 已结束的展会
        }),
        ctx.$axios.post('/api/innerChain/page', {
          category: '展会推荐',
          pageSize: 20,
          // locale: detail.locale,
        }),
        ...requestList,
      ];

      const result: any[] = await Promise.all(requestList);
      // industryRes, areaRes,
      const [areaRes, recommendInfoRes, fairRes, endExhibitionRes, topicRes] =
        result;

      let topicList = [];
      if (topicRes.code === ResponseCode.SUCCESS) {
        topicList = topicRes.data.records;
      }

      let recommendInfo: any = {};
      if (recommendInfoRes.code == '1') {
        recommendInfo = recommendInfoRes.data;
      }

      let industry: any[] = [];

      // if (industryRes?.code === ResponseCode.SUCCESS) {
      //   industry = industryRes.data;
      // }

      let areas: any[] = [];

      if (areaRes?.code === ResponseCode.SUCCESS) {
        areas = areaRes.data;
      }

      let fair: any = {};
      let fairList: any = [];
      if (fairRes?.code === ResponseCode.SUCCESS) {
        fair = fairRes.data;
        fairList = fairRes.data.records;
        if (pageNum > 1 && pageNum > fairRes.data.pages) {
          return ctx.error({
            message: 'Page not found',
            path: ctx.route.fullPath,
            statusCode: 404,
          });
        }
        if (fair.records && fair.records.length > 0) {
          // 展馆号列表
          let pavilionIdList: any[] = fair.records.map(
            (item: any) => item.pavilionId
          );
          const pavilionRes: any[] = await Promise.all([
            ctx.$axios.post('/api/pavilionApi/list', {
              pavilionIdList: pavilionIdList, // 根据展馆id查询展馆信息
              pageNum: 1,
              pageSize: 30,
            }),
          ]);

          const [pavilionListRes] = pavilionRes;

          // 展馆列表
          if (pavilionListRes.code === ResponseCode.SUCCESS) {
            fairList.map((item: any) => {
              // 如果是国际展会,需要查找地区的上一级id(洲的id)
              if (item.regionType == 1) {
                outerLoop: for (let i = 0; i < areas.length; i++) {
                  let areaItem = areas[i];
                  let regionList = areaItem.areas;
                  for (let j = 0; j < regionList.length; j++) {
                    if (item.areaId == regionList[j].id) {
                      item['regionId'] = areaItem.id;
                      break outerLoop;
                    }
                  }
                }
              }

              // 查找展馆信息
              pavilionListRes.data.records.map((pavilion: any) => {
                if (item.pavilionId === pavilion.id) {
                  item['pavilionName'] = pavilion.pavilionName;
                }
              });
            });
          }
        }
      }

      // 已结束的展会
      let endfair: any = {};
      let endFairList: any = [];
      if (endExhibitionRes?.code === ResponseCode.SUCCESS) {
        endfair = endExhibitionRes.data;
        endFairList = endExhibitionRes.data.records;

        if (endfair.records && endfair.records.length > 0) {
          // 展馆号列表
          let pavilionIdList: any[] = endfair.records.map(
            (item: any) => item.pavilionId
          );
          const pavilionRes: any[] = await Promise.all([
            ctx.$axios.post('/api/pavilionApi/list', {
              pavilionIdList: pavilionIdList, // 根据展馆id查询展馆信息
            }),
          ]);

          const [pavilionListRes] = pavilionRes;

          // 展馆列表
          if (pavilionListRes.code === ResponseCode.SUCCESS) {
            endFairList.map((item: any) => {
              // 如果是国际展会,需要查找地区的上一级id(洲的id)
              if (item.regionType == 1) {
                outerLoop2: for (let i = 0; i < areas.length; i++) {
                  let areaItem = areas[i];
                  let regionList = areaItem.areas;
                  for (let j = 0; j < regionList.length; j++) {
                    if (item.areaId == regionList[j].id) {
                      item['regionId'] = areaItem.id;
                      break outerLoop2;
                    }
                  }
                }
              }
              // 查找展馆信息
              pavilionListRes.data.records.map((pavilion: any) => {
                if (item.pavilionId === pavilion.id) {
                  item['pavilionName'] = pavilion.pavilionName;
                }
              });
            });
          }
        }
      }

      let interfaceResponseTime = ctx.store.state.global.interfaceResponseTime;
      // 设置一个自定义响应头(nuxtTraceId 记录日志位置)
      if (ctx.res) ctx.res.setHeader('nuxtTraceId', interfaceResponseTime);
      if (ctx.$winstonLog) ctx.$winstonLog.info(interfaceResponseTime);
      ctx.store.commit('global/setInterfaceResponseTime', '');

      return {
        lang,
        langApi,
        id,
        recommendInfo,
        keyword,
        pageNum,
        pageSize,
        topicList,
        fair,
        fairList,
        endfair,
        endFairList,
        industry,
      };
    } catch (e) {
      ctx.error(e as any);
    }
  },
  data(): Record<string, any> {
    return {
      firstIndustryId: 0,
      secondIndustryId: 0,
      type: 0,
      area: 0,
      province: 0,
      city: 0,
      date: '0',
      value: '',
      fair: {},
      industry: [],
      secondIndustry: [],
      areaList: [],
      cityList: [],
      checkTags: [], //  已选条件
      moreArea: false, // 地区更多
    };
  },
  computed: {
    areas(): any[] {
      if (this.moreArea) {
        if (this.area === 0) {
          return this.areaList;
        }
        return this.cityList;
      } else {
        if (this.area === 0) {
          return this.$data.areaList?.slice(0, 8) || [];
        }
        return this.cityList?.slice(0, 8) || [];
      }
    },
  },
  created(): void {},
  mounted() {},
  methods: {
    handleNewsUrl,
    handleToDetail(id: any) {
      window.open(`/exhibition/${id}.html`);
    },
    /**
     * 获取全部行业
     */
    getIndustry() {
      this.$axios
        .get(`/api/industry/getAll?locale=${this.langApi}`)
        .then((res: any) => {
          if (res.code == 1) {
            this.industry = res.data;

            let params = this.$route.params;
            // let firstIndustryId = Number(params.f)
            if (this.firstIndustryId > 0) {
              // 已选一级行业
              const item = this.industry.find(
                (item: any) => item.industryId == this.firstIndustryId
              );
              this.secondIndustry = item?.children; // 二级行业
              this.checkTags.push({
                name: item?.industryName,
                type: 'firstIndustry',
                id: this.firstIndustryId,
              });
            }
            if (this.secondIndustryId > 0) {
              // 已选二级行业
              const item = this.secondIndustry?.find(
                (item: any) => item.id == this.secondIndustryId
              );
              if (!item) {
                this.$message.error('二级行业不存在');
                return;
              }
              this.checkTags.push({
                name: item?.industryName,
                type: 'secondIndustry',
                id: this.secondIndustryId,
                pid: this.firstIndustryId,
              });
            }
          }
        });
    },

    changePager(page: number) {
      const { id } = this;

      this.$router.push({
        path: `/exporec-${id}-${page}/`,
        query: this.$route.query,
      });
    },
  },
  head(): Record<string, any> {
    const { f: id, page }: any = this.$route.params;
    const { keyword = '' } = this.$route.query;

    const year = moment().year();

    let recommendName = this.$data.recommendInfo.name || '';

    let title =
      this.$data.recommendInfo.title ||
      `${recommendName}_${recommendName}${year}年排期`;
    let keywords =
      this.$data.recommendInfo.seoKeyword ||
      `${recommendName}、${recommendName}排期、${recommendName}地点、${recommendName}时间表、${recommendName}门票`;
    let description =
      this.$data.recommendInfo.introduce ||
      `聚展网为您提供关于${recommendName}的2024年展会信息，包含${recommendName}时间表、排期、展会地点、展会门票购买、展位申请、参展商名录/电子会刊等信息服务内容`;

    let pageText = '';

    // 右上角输入框搜索内容
    if (keyword) {
      title = `${keyword}${pageText}-聚展`;
      keywords = `${keyword},展览会,博览会,交易会,展会时间,展会地点,展会门票`;
      description = `聚展网为您提供${year}最新最全的${keyword},${keyword}展会排期,${keyword}展览会信息及资讯,是您参展${keyword}展会最好的展会服务平台`;
    }

    return {
      title,
      meta: [
        // {
        //   name: 'mobile-agent',
        //   'http-equiv': 'mobile-agent',
        //   content: `format=html5; url=https://m.jufair.com/exporec-${id}-${page}`,
        // },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.jufair.com/exporec-${id}-1/`,
        },
      ],
    };
  },
});
