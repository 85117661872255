import { render, staticRenderFns } from "./exporec.vue?vue&type=template&id=a38be364&scoped=true&"
import script from "./exporec.vue?vue&type=script&lang=ts&"
export * from "./exporec.vue?vue&type=script&lang=ts&"
import style0 from "./exporec.vue?vue&type=style&index=0&id=a38be364&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a38be364",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default,Empty: require('/root/workspace/com.jufair.vue_Z6yh/components/Empty/index.vue').default,Pagination: require('/root/workspace/com.jufair.vue_Z6yh/components/Pagination/index.vue').default})
